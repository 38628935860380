export const res = {
    success,
    failure,
    loading,
}

function success(type, data) {
    return {
        type: type,
        payload: {
            data,
        },
    }
}

function failure(type, error) {
    return {
        type: type,
        payload: {
            error,
        },
    }
}

function loading(type) {
    return {
        type: type,
    }
}
